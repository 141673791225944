// Default Color
$white: #ffffff !default;
$white-2: #f4f4f4;
$white-3: #e9e9e9;
$white-50: #f0f0f0;

$black: #000000 !default;
$black-5: #0000000d;

$azul: #2153eb;
$windows-blue: #39a3ca;
$sea-green: #52f8a8;
$warm-grey: #9b9b9b;
$greyish-brown: #4a4a4a;
$gallery: #efefef;
$silver: #c9c9c9;
$manatee: #8e8e93;
$table-grey: #eeeeee;
$blue-light: #007aff;
$red-fire: #da383f;
$primary-green: #52f7a8;
$secondary-green: #2ec4b6;
$secondary-red: #f64d54;

$colors: (
  'white': $white,
  'white-2': $white-2,
  'white-3': $white-3,
  'white-50': $white-50,
  'black': $black,
  'black-5': $black-5,
  'azul': $azul,
  'windows-blue': $windows-blue,
  'sea-green': $sea-green,
  'warm-grey': $warm-grey,
  'greyish-brown': $greyish-brown,
  'gallery': $gallery,
  'silver': $silver,
  'manatee': $manatee,
  'table-grey': $table-grey,
  'blue-light': $blue-light,
  'red-fire': $red-fire,
  'primary-green': $primary-green,
  'secondary-green': $secondary-green,
  'secondary-red': $secondary-red,
);

@each $name, $color in $colors {
  .color-#{$name} {
    color: $color !important;
  }

  .bg-#{$name} {
    background-color: $color !important;
  }

  .border-#{$name} {
    border-color: $color !important;
  }
}
