.color-picker-preview {
  cursor: pointer;
  width: 80px;
  height: 40px;
  background-color: aliceblue;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}
