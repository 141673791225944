.category-preview {
  width: 300px;
  min-height: 56px;
  border: 2px solid inherit;
  border-radius: 6px;
  padding: 12px 16px;
  align-items: center;
  display: flex;

  &__icon {
    font-size: 18px;
    margin-right: 16px;
  }
}

.category-preview-title-container {
  display: flex;
  flex-direction: column;
}

.category-preview-title {
  font-weight: bold;
  margin-bottom: 2px;
}

.category-preview-sub-title {
  font-size: 14px;
}
