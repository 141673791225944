.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  background-color: white;
  border-top: solid 1px $white-2;
  justify-content: space-between;
  margin: 0 auto;
  height: $footer-height;
  padding-left: 84px;
  align-items: center;
  padding-right: 52px;
}

.social-media-links {
  width: 130px;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copyright-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $greyish-brown;
  font-size: $font-size-sm;
}

.hourly-footer-icon {
  height: 44px;
  width: 164px;
}
