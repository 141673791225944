@font-face {
  font-family: 'AntDesign';
  src: url('./fonts/AntDesign.ttf') format('truetype');
}

@font-face {
  font-family: 'Entypo';
  src: url('./fonts/Entypo.ttf') format('truetype');
}

@font-face {
  font-family: 'EvilIcons';
  src: url('./fonts/EvilIcons.ttf') format('truetype');
}

@font-face {
  font-family: 'Feather';
  src: url('./fonts/Feather.ttf') format('truetype');
}

@font-face {
  font-family: 'FontAwesome';
  src: url('./fonts/FontAwesome.ttf') format('truetype');
}

@font-face {
  font-family: 'FontAwesome5Brands';
  src: url('./fonts/FontAwesome5_Brands.ttf') format('truetype');
}

@font-face {
  font-family: 'FontAwesome5_Regular';
  src: url('./fonts/FontAwesome5_Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'FontAwesome5';
  src: url('./fonts/FontAwesome5_Solid.ttf') format('truetype');
}

@font-face {
  font-family: 'Fontisto';
  src: url('./fonts/Fontisto.ttf') format('truetype');
}

@font-face {
  font-family: 'Foundation';
  src: url('./fonts/Foundation.ttf') format('truetype');
}

@font-face {
  font-family: 'Ionicons';
  src: url('./fonts/Ionicons.ttf') format('truetype');
}

@font-face {
  font-family: 'MaterialCommunityIcons';
  src: url('./fonts/MaterialCommunityIcons.ttf') format('truetype');
}

@font-face {
  font-family: 'MaterialIcons';
  src: url('./fonts/MaterialIcons.ttf') format('truetype');
}

@font-face {
  font-family: 'Octicons';
  src: url('./fonts/Octicons.ttf') format('truetype');
}

@font-face {
  font-family: 'SimpleLineIcons';
  src: url('./fonts/SimpleLineIcons.ttf') format('truetype');
}

@font-face {
  font-family: 'Zocial';
  src: url('./fonts/Zocial.ttf') format('truetype');
}
